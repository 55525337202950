@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    /* ILLUSTRATIONS */
    --illustration-spot-primary-color: #ef7f88;
    --illustration-spot-secondary-color: #6c81ba;
    --illustration-spot-secondary-light-color: #e2f2f7;
    /* FONT SMOOTHING */
    -webkit-font-smoothing: antialiased;
  }

  :root {
    --background-color: #f9f8f7;
    --text-color: #1d192c;
    --link-color: #4a48c6;
    --primary-color: #3d3d63;
    --secondary-color: #4a48c6;
    --error-color: #da3a25;
  }
}

@layer components {
  .dropdown-arrow {
    @apply pr-5 relative;
    @apply after:top-1/2 after:mt-[-0.35em] after:mx-[1px] after:w-[0.5em] after:h-[0.5em] after:absolute after:right-1;
    @apply after:border-r-2 after:border-r-current after:border-b-2 after:border-b-current;
    @apply after:transition-all after:rotate-45;
    @apply dark:after:border-[0.2em] dark:after:w-[0.4em] dark:after:h-[0.4em] dark:after:opacity-75;
    @apply dark:after:border-t-transparent dark:after:border-l-transparent dark:after:mt-[-0.25em] dark:after:mx-[3px];
  }

  .dropup-arrow {
    @apply dropdown-arrow after:-rotate-135 after:mt-[-0.15em];
    @apply dark:after:mt-[0];
  }

  .right-arrow {
    @apply dropdown-arrow after:-rotate-45 after:mt-[-0.2em];
  }

  .left-arrow {
    @apply dropdown-arrow after:rotate-135 after:mt-[-0.2em] after:right-auto after:left-1 pl-5;
  }

  .loading-dot {
    @apply pr-5 relative after:top-1/2 after:rounded-full after:bg-current after:right-2 after:mt-[-.125rem] after:w-1 after:h-1 after:content-center after:absolute after:animate-ping;
  }

  .checkmark::after {
    display: block;
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translateX(-50%) translateY(-66%) rotate(45deg);
  }
}

@layer utilities {
  .min-h-screen {
    min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    min-height: var(--vh, 100vh);
  }

  /* reduce HTML payload and snapshot complexity */
  .blade-item {
    @apply py-2 hover:text-link text-sm flex items-center;
  }

  /* Looking for .container-xl? See: packages/theming/src/components/container/index.css.ts */

  /*
    adds underline treatment to anchor links that have a parent with this class
    if we apply universally it applies styles to buttons that are also anchor tags
    and underlines text within the buttons, and we don't want that.
  */
  .accessible-anchor :is(a) {
    @apply hover:no-underline;
  }
}
